import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import ArrowDown from 'src/icons/ArrowDown';
import useSWR from 'swr';
import { serializeCacheKey } from '@/lib/contentful/utils';
import { getAllAppAlgoliaFacets } from '../AppSidebar/getAllAppFacets';
import { useAppsCurrentcategories } from '../AppsProvider';
const SEARCH_DELAY = 500;
let timerId: NodeJS.Timeout | undefined = undefined;
let isTyping = false;
export const useSearchStatus = () => {
  return {
    isTyping
  };
};
type Props = {
  searchInputPlaceholder?: string;
  className?: string;
};
export function AppsSearch({
  searchInputPlaceholder,
  className
}: Props) {
  const {
    push,
    asPath,
    query
  } = useRouter();
  const {
    data
  } = useSWR(serializeCacheKey('appAlgoliaFacets'), () => getAllAppAlgoliaFacets());
  const [inputValue, setInputValue] = useState(typeof query.query === 'string' ? query.query : '');
  useEffect(() => {
    if (!query.query) return setInputValue('');
    if (Array.isArray(query.query)) return setInputValue(query.query.join(''));
    setInputValue(query.query);
  }, [query.query]);
  const {
    parentCategory
  } = useAppsCurrentcategories();
  const handleAppCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectValue = event.target.value;
    if (selectValue === 'all-categories') {
      window.location.href = `${window.location.origin}/apps/categories`;
    } else {
      window.location.href = `${window.location.origin}/apps/categories/${selectValue}`;
    }
  };
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputValue(value);
    if (timerId) {
      clearTimeout(timerId);
    }
    delete query.page;
    if (!asPath.includes('/apps/categories/')) {
      timerId = setTimeout(() => push({
        pathname: '/apps/categories/',
        query: {
          query: value || []
        }
      }), 700);
    } else {
      timerId = setTimeout(() => push({
        query: {
          ...query,
          query: value || []
        }
      }, undefined, {
        shallow: true
      }), SEARCH_DELAY);
    }
  }
  return <div className={clsx('flex flex-col lg:flex-row gap-8', className)} data-sentry-component="AppsSearch" data-sentry-source-file="AppsSearch.tsx">
			<div className="relative flex items-center focus-within:drop-shadow-lg">
				<select className="max-lg:w-full w-[357px] h-full rounded-md py-3 px-4 focus:outline-none border-0 bg-white text-bc-black ring-1 ring-gray-1000 focus:ring-gray-1000 sm:text-sm sm:leading-6 bg-none" onChange={handleAppCategoryChange} value={parentCategory?.slug || 'all-categories'} data-testid="app-category-select">
					<option value="all-categories">All Categories</option>

					{data?.parentCategories?.map((category: {
          title: string | undefined;
          slug: string | undefined;
        }, i: string | number | null | undefined) => {
          if (!category?.title) return null;
          return <option key={i} value={category.slug || ''}>
									{category.title}
								</option>;
        })}
				</select>

				<ArrowDown className="text-gray-900 absolute right-5 pointer-events-none" data-sentry-element="ArrowDown" data-sentry-source-file="AppsSearch.tsx" />
			</div>

			<div id="search-combobox" className="relative focus-within:drop-shadow-lg z-20 flex-grow">
				<input id="combobox" type="search" className="w-full rounded-md border-0 bg-white py-3 pl-6 pr-14 text-bc-black ring-1 ring-gray-1000 focus:ring-gray-1000 sm:text-sm sm:leading-6 [&::-webkit-search-cancel-button]:hidden peer" role="combobox" aria-controls="options" aria-expanded="false" placeholder={searchInputPlaceholder} value={inputValue} onChange={handleChange} data-testid="search-by-query-input" />

				<button type="button" className="absolute inset-y-0 right-6 focus:outline-none pointer-events-none peer-focus:hidden">
					<svg className="h-4 w-4 text-gray-900" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-source-file="AppsSearch.tsx">
						<path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="AppsSearch.tsx"></path>
					</svg>
				</button>
			</div>
		</div>;
}